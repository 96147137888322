.products {
    background: #ced5db url("../img/products-block-bg.jpg") 50% 50%;
    background-size: cover;
    padding: 98px 0 150px;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
        padding: 81px 0 124px;
    }
    @media screen and (max-width: 1023px) {
        padding: 45px 0 55px;
    }
    &__title {
        color: $color-accent;
    }
    &__items {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__item {
        width: 48.7%;
        background: rgba($color-light-violet, 0.85);
        padding: 25px 43px 26px 25px;
        margin-top: 25px;
        position: relative;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            padding-bottom: 20px;
        }
        @media screen and (max-width: 1023px) {
            padding: 25px 20px;
            width: 100%;
        }
    }
    &__series {
        border-bottom: 3px solid $color-pink;
        color: white;
        display: inline-block;
        font-size: 1.5em;
        font-weight: 300;
        letter-spacing: .16em;
        line-height: 1.4;
        margin: 0;
        text-transform: uppercase;
    }
    &__img {
        margin-top: 15px;
        img {
            margin: 0 auto;
        }
    }
    &__description {
        color: #fff;
        background-color: $color-accent;
        padding: 27px 27px;
        margin-top: 35px;
        position: relative;
        left: -28px;
        width: 109%;
        height: 297px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            margin-top: 35px;
            height: 247px;
        }
        @media screen and (max-width: 1023px) {
            margin-top: 27px;
            left: -20px;
            width: 102%;
        }
        @media screen and (max-width: 575px) {
            width: 104%;
            height: 280px;
        }
        @media screen and (max-width: 425px) {
            height: 380px;
        }
    }
    &__caption {
        color: $color-pink;
        font-size: 0.92em;
        letter-spacing: 0.2em;
        line-height: 1.84em;
        margin: 0 0 10px;
        text-transform: uppercase;
    }
    &__list {
        margin: 0;
        padding: 0 0 0 20px;
        li {
            line-height: 2.2;
        }
    }
    &__button {
        position: absolute;
        bottom: 20px;
        right: 19px;
        border: 1px solid white;
        color: white;
        fill: white;
        height: 38px;
        line-height: 36px;
        padding: 0 15px;
        text-decoration: none;
        transition: background-color .18s, color .18s, fill .18s;
        &:hover {
            background: white;
            color: #153f7f;
            fill: #153f7f;
        }
        svg {
            height: .6em;
            margin-left: .25em;
        }
    }
    &__controls {
        display: none;
        @media screen and (max-width: 1023px) {
            display: flex;
        }
    }
    &__prev {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        cursor: pointer;
        z-index: 1;
    }
    &__next {
        position: absolute;
        right: -18px;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        cursor: pointer;
        @media screen and (max-width: 1023px) {
            right: 0;
        }
    }
}