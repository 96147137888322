.spec {
  padding: 70px 0 97px;
  @media screen and (max-width: 1023px) {
    padding: 30px 0 40px;
  }
  &_norma {
    padding: 70px 0 97px;
  }
  &__title {
    color: #ededed;
    font-size: 10.8em;
    font-weight: 100;
    letter-spacing: .36em;
    line-height: 1;
    margin: 0 0 43px;
    text-align: right;
    text-transform: uppercase;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      letter-spacing: .05em;
      margin: 0 0 40px 506px;
    }
    @media screen and (max-width: 1023px) {
      display: none;
    }
    &_norma {
      letter-spacing: .08em;
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1023px) {
      display: block;
    }
  }
  &__text {
    line-height: 1.75;
    @media screen and (max-width: 1023px) {
      margin-left: 0;
    }
  }
  &__caption {
    color: $color-accent;
    text-align: left;
    letter-spacing: 0.25em;
    margin: 0 0 23px 0;
    text-transform: uppercase;
    font-weight: 700;
    @media screen and (max-width: 1023px) {
      font-size: 1em;
      margin-left: 0;
    }
    &_select {
      color: $color-pink;
      margin-left: 0;
    }
  }
  &__table {
    border-collapse: collapse;
    margin-top: 23px;
    width: 100%;
    &:only-child {
      margin: 0;
    }
    tr {
      background: #fdfdfd;
      &:nth-child(odd) {
        background: #ececec;
      }
    }
    td, th {
      line-height: 1.5;
      padding: .8em .5em;
      vertical-align: top;
      @media screen and (max-width: 1023px) {
        font-size: 0.8em;
      }
      &:first-child {
        @media screen and (min-width: 1024px) {
          padding-left: 1.5em;
          width: 30%;
        }
        @media screen and (max-width: 1023px) {
          width: 29%;
        }
      }
      &:nth-child(2) {
        @media screen and (min-width: 1024px) {
          width: 28%;
        }
        @media screen and (max-width: 1023px) {
          width: 27%;
        }
      }
      &:last-child {
        text-align: center;
        @media screen and (min-width: 1024px) {
          padding-right: 1.5em;
          width: 18%;
        }
        @media screen and (max-width: 1023px) {
          width: 17%;
        }
      }
    }
    td {
      color: $color-accent;
    }
    th {
      background: $color-light-violet;
      color: white;
      font-weight: normal;
      text-align: left;
    }
  }
  &__scroll {
    max-height: 22.4em;
    overflow: auto;
    @media screen and (max-width: 1023px) {
      max-height: none;
    }
    .mCSB_inside > .mCSB_container {
      margin-right: 0;
    }
    .mCSB_scrollTools {
      margin: 0 auto;
      opacity: 1;
      width: 6px;
      .mCSB_dragger .mCSB_dragger_bar {
        background: $color-pink;
        border-radius: 0;
        width: 6px;
      }
      .mCSB_draggerRail {
        background: #e1e1e1;
        width: 6px;
      }
    }
  }
}



