.menu {
  display: flex;
  justify-content: center;
  transition: background-color 0.36s;
  height: 100%;
  @media screen and (max-width: 1023px) {
    display: block;
    padding: 15px 0;
    height: auto;
  }
  &__item {
    display: flex;
    align-items: center;
    margin: 0 26px;
    color: inherit;
    font-size: 1.28em;
    text-transform: uppercase;
    text-decoration: none;
    border-top: 4px solid transparent;
    transition: all 0.35s;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      margin: 0 23px
    }
    @media screen and (max-width: 1023px) {
      border: none;
      margin: 0 0 10px;
      font-size: 1.664em;
    }
    &:hover {
      color: $color-accent;
    }
    &_active {
      border-top-color: white;
    }
  }
}