.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(30px);
  transition: all 0.35s;
  z-index: 9;
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    transform: translateY(25px);
  }
  @media screen and (max-width: 1023px) {
    background: $color-accent;
    transform: none;
  }
  &__stuck {
    background: rgba(#ededed, 1);
    transform: translateY(0);
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      transform: translateY(0);
    }
    .header__logo {
      height: 80px;
      @media screen and (max-width: 1279px) {
        height: 65px;
      }
    }
  }
  &__container {
    display: flex;
    justify-content: space-between;
  }
  &__logo {
    background: $color-accent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 245px;
    height: 113px;
    transition: all 0.35s;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      width: 204px;
      height: 94px;
    }
    @media screen and (max-width: 1023px) {
      height: 65px;
      margin-right: -20px;
      width: auto;
    }
    img {
      width: 74%;
      margin: 10px 0 0 -10px;
      @media screen and (max-width: 1023px) {
        margin: 0;
      }
    }
  }
  &__nav {
    display: block;
    background: rgba(#ededed, 0.9);
    width: 100%;
    position: relative;
    @media screen and (max-width: 1023px) {
      display: none;
      height: 100vh;
      position: absolute;
      left: 0;
      top: 100%;
      padding: 0 20px 20px;
    }
  }
  &__trigger {
    display: none;
    height: 65px;
    margin-left: -20px;
    position: relative;
    width: 65px;
    @media screen and (max-width: 1023px) {
      display: block;
    }
    span {
      background: $color-pink;
      display: block;
      height: 2px;
      left: 20px;
      position: absolute;
      right: 20px;
      top: 50%;
      transition: opacity 0.35s, transform 0.35s;
      &:first-child {
        transform: translate3d(0, -7px, 0);
      }
      &:nth-child(2) {
        transform: translate3d(0, -1px, 0);
      }
      &:last-child {
        transform: translate3d(0, 5px, 0);
      }
    }
    &_active {
      span {
        &:first-child {
          transform: translate3d(0, -1px, 0) rotate(-45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          transform: translate3d(0, -1px, 0) rotate(45deg);
        }
      }
    }
  }
}