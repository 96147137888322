.features {
  padding: 120px 0 80px;
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    padding: 93px 0 66px;
  }
  @media screen and (max-width: 1023px) {
    padding: 45px 0 55px;
  }
  &__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__item {
    width: 31%;
    margin-bottom: 35px;
    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      margin-bottom: 59px;
    }
    @media screen and (max-width: 1023px) {
      width: 100%;
    }
    &:last-child {
      @media screen and (max-width: 1023px) {
        margin-bottom: 0;
      }
    }
  }
  &__img {
    position: relative;
    img {
      width: 100%;
    }
  }
  &__inner {
    position: absolute;
    top: 15px;
    bottom: 15px;
    right: 15px;
    left: 15px;
    background-color: rgba($color-light-violet, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 40%;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.15em;
    text-align: center;
    height: 100px;
    padding: 0 15px;
    background-color: #eaeaea;
    @media screen and (max-width: 1279px) {
      height: 80px;
    }
  }
  &__controls {
    display: none;
    @media screen and (max-width: 1023px) {
      display: flex;
    }
  }
}








