.scheme {
    &__inner {
        background-position: 50% 50%;
        background-size: cover;
        height: 720px;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            height: 597px;
        }
        @media screen and (max-width: 1023px) {
            height: 660px;
        }
        @media screen and (max-width: 575px) {
            height: 605px;
        }
        @media screen and (max-width: 425px) {
            height: 550px;
        }
        &_base {
            background-image: url(../img/base-bg.jpg);
        }
        &_norma {
            background-image: url(../img/norma-bg.jpg);
        }
    }
    &__container {
        padding: 145px 659px 0 0;
        position: relative;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            padding: 120px 547px 0 0;
        }
        @media screen and (max-width: 1023px) {
            height: 100%;
            padding: 0;
        }
    }
    &__img {
        position: absolute;
        right: -80px;
        top: 147px;
        z-index: 1;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            right: 0;
            transform: scale(0.8);
            transform-origin: 100% 0;
        }
        @media screen and (max-width: 1023px) {
            right: 50%;
            top: 50px;
            transform: scale(0.7) translateX(50%);
            transform-origin: 100% 0;
        }
        @media screen and (max-width: 575px) {
            transform: scale(0.6) translateX(50%);
        }
        @media screen and (max-width: 425px) {
            transform: scale(0.5) translateX(50%);
        }
        img {
            max-width: 100%;
        }
        &_base {
            width: 610px;
            height: 385px;
            .scheme__img-container {
                width: 340px;
                height: 100%;
            }
        }
        &_norma {
            width: 610px;
            height: 389px;
            .scheme__img-container {
                width: 340px;
                height: 100%;
            }
        }
    }
    &__badge {
        position: absolute;
        display: block;
        width: 22px;
        height: 22px;
        background: white;
        border: 9px solid $color-accent;
        border-radius: 100%;
        opacity: 0.6;
        transition: opacity 0.36s, transform 0.36s;
        &_active {
            opacity: 0.87;
            transform: scale(1.3);
        }
    }
    &__tooltip {
        align-items: center;
        color: white;
        display: flex;
        left: -115px;
        max-width: 100%;
        opacity: 0;
        position: absolute;
        top: 100%;
        transform: scale(0);
        transition: opacity .36s, transform .36s;
        &_active {
            opacity: 1;
            transform: scale(1);
        }
    }
    &__tooltip-img {
        background: $color-light-violet;
        border: 4px solid $color-accent;
        border-radius: 100%;
        flex: 0 0 160px;
        height: 160px;
        line-height: 160px;
        margin-right: 20px;
        overflow: hidden;
        width: 160px;
        img {
            width: 100%;
        }
    }
    &__tooltip-text {
        color: white;
        font-size: 14px;
        flex-shrink: 0;
        @media screen and (max-width: 1023px) {
            font-size: 18px;
        }
    }
    &__description {
        background: rgba($color-light-violet, 0.8);
        color: white;
        height: 762px;
        padding: 66px 40px 72px 35px;
        position: relative;
        @media screen and (min-width: 1024px) and (max-width: 1279px) {
            height: 634px;
            padding: 50px 30px 60px;
        }
        @media screen and (max-width: 1023px) {
            bottom: 0;
            height: 245px;
            left: -20px;
            padding: 25px 20px 60px;
            position: absolute;
            right: -20px;
        }
    }
    &__series {
        font-size: 6.28em;
        font-weight: 300;
        letter-spacing: .16em;
        line-height: 1;
        margin: 0 0 .02em;
        text-transform: uppercase;
        @media screen and (max-width: 1023px) {
            font-size: 3.6em;
        }
    }
    &__caption {
        background: $color-pink;
        color: $color-accent;
        display: inline-block;
        font-size: .8em;
        font-weight: bold;
        height: 1.4em;
        letter-spacing: .5em;
        line-height: 1.55em;
        padding: 0 .6em 0 .9em;
        text-transform: uppercase;
    }
    &__subtitle {
        font-size: 2.15em;
        font-weight: 300;
        line-height: 1.5em;
        margin: 58px 0 0;
        @media screen and (max-width: 1023px) {
            font-size: 1.14em;
            margin-top: 22px;
        }
    }
    &__choice {
        position: absolute;
        top: 0;
        left: 0;
    }
    &__choice-item {
        width: 145px;
        height: 145px;
        border: 2px solid transparent;
        margin-bottom: 27px;
        background-color: rgba(255, 255, 255, 0.2);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &_active {
            border-color: $color-light-violet;
            background-color: transparent;
        }
    }
    &__img-container {
        position: absolute;
        top: 0;
        right: 0;
    }
    &__item {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;
        &_active {
            opacity: 1;
            visibility: visible;
        }
        &[data-scheme='base-2'],
        &[data-scheme='norma-2'] {
            top: 50%;
            transform: translate3d(0, -50%, 0);
        }
    }
}